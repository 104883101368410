import React from 'react'
import { Route, Routes } from "react-router-dom";
import Cuenta from '../../Components/Customer/Cuenta/Cuenta'


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterCuenta(props) {

    

    return (
        <Routes>
            <Route path=""      element={<Cuenta {...props} />} />
        </Routes>
    )
}

export default RouterCuenta;
