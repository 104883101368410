import React, { Component } from 'react'
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Row, Col, message, Typography, Result } from 'antd';
import axios from "axios"

//Componentes
import { RenderMoney } from "../../Utils"

const { Title, Text } = Typography;

const VentaExitosa = ({ folio, onClose = ()=>{}, setStep=()=>{} }) => {

    let navigate = useNavigate()

    return (
        <Result
            status="success"
            title="¡Compra Exitosa!"
            subTitle={ <Text>
                Número de folio: <strong>{folio ?? "-"}</strong>. Se le enviará un correo de confirmación de su orden. 
                Gracias por su compra. Puede hacer seguimiento de su pedido en su cuenta.
            </Text>}
            extra={[
                <Button type="primary" key="store" onClick={() => {
                    navigate("/productos")
                    onClose()
                    setStep(0)
                }}>
                    Ir a la Tienda
                </Button>,
                <Button key="orders" onClick={() => navigate("/customer/pedidos")}>
                    Ver mis pedidos
                </Button>,
            ]}
        />
    );
};

export default class StepPayment extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            total: 0,
            venta: {},
        }
    }

    componentDidMount(){
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getCarrito()
    }

    /**
     * @method gatCarrito
     * @descripcion Obtiene le carrito de compra, para mostrar la informacion
     * */
    getCarrito = async () => {
        this.setState({ loading: true });
        try {
            const { data } = await axios.get("/carrito");
            this.setState({ total: data.total });
        } catch (error) {
            console.error("Error al obtener el carrito:", error.message || error);
            message.error("No se pudo cargar la información del carrito. Intenta nuevamente.");
        } finally {
            this.setState({ loading: false });
        }
    };

    /**
     * @method onFinish
     * @descripcion Se ejecuta al llenar la informacion de pago, y crear la venta
     * */
    onFinish =  async (values) => {
       
        this.setState({ loading: true });
        try {
            const { data } = await axios.post("/carrito/pay",{
                ...values
            });
            
            this.setState({venta: data})

            message.success("Compra realizada con exito")

        } catch (error) {
            console.error(error);
            message.error(error?.response?.data?.message ?? "No se pudo realizar la compra del carrito. Intenta nuevamente.");
        } finally {
            this.setState({ loading: false });
        }

    }

    render() {

        if(this.state.venta.folio)
            return <VentaExitosa folio={this.state.venta.folio} {...this.props}/>

        return (
            <Row>
                <Col span={12} className="flex-right">
                    <Title level={3} className="m-0"> Total: </Title>
                </Col>
                <Col span={12} className="flex-right">
                    <RenderMoney monto={this.state.total} valueStyle={{ fontSize: "24px" }}/>
                </Col>
                <Col span={24} className="mt-2">
                    <Form
                        layout={"vertical"}
                        onFinish={this.onFinish}
                    >
                        <Row justify="center" className="center" gutter={[16, 8]}>
                            <Col span={24}>
                                <Form.Item
                                    name="card_number"
                                    label="Numero de Tarjeta"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Card Number es requerido',
                                        },
                                    ]}
                                >
                                    <Input maxLength={16} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="expiration_date"
                                    label="Expiracion"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Expiration es requerido',
                                        },
                                    ]}
                                >
                                    <Input maxLength={5} placeholder='MM/DD' />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="cvv"
                                    label="CVV"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Security Code es requerido',
                                        },
                                    ]}
                                >
                                    <Input type='password' />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Button type='primary' block style={{ background: 'green' }} loading={this.state.loading} htmlType="submit">Pagar</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            
        )
    }
}