
import React, { Component, useContext } from 'react'
import { Form, Input, Button, Row, Col, message, Spin, Typography, Modal, Radio, Space, Checkbox, DatePicker, Select } from 'antd';
import axios from "axios";

import { User, SetUser } from '../../../Hooks/Logged';
import { SetCarrito, Carrito } from '../../../Hooks/Carrito';
import ModalDirecciones from '../../Customer/Cuenta/ModalDirecciones';

const { Title, Text } = Typography;
const { Option } = Select;

class StepAddress extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            direcciones: {
                data: [],
                limit: 20,
                page: 1,
            },
            continue: false,
            pick_deliver: false,
            disabledTime: true,
        }
    }

    refForm = React.createRef();

    componentDidMount() {
        if (this.props.user.cliente_id) {
            this.getDirecciones()
        } else {
            message.error("Error")
        }
    }

    /**
     * @method getDirecciones
     * @description Obtiene las direcciones del servidor y actualiza el estado
     **/
    getDirecciones = async () => {
        this.setState({ loading: true });
        try {
            const response = await axios.get('/direcciones');
            
            if (response && response.data) {
                this.setState({
                    direcciones: { ...response.data },
                });
            } else {
                throw new Error("La respuesta del servidor no contiene datos válidos.");
            }
        } catch (error) {
            console.error(error);
            message.error(error?.response?.data?.message ?? "No se pudo obtener las direcciones.");
        } finally {
            this.setState({ loading: false });
        }
    };



    /**
     * @memberof StepDireccion
     * @method onFinish
     * @description Actualiza la venta con la direccion seleccionada
     */
    onFinish = (values) => {
        this.setState({ loading: true })
        axios.post('/carrito/direccion', {
            ...values,
        }).then(response => {
            this.props.next()
        }).catch(error => {
            message.error(error?.response?.data?.message ?? "Error al guardar la dirección")
        }).finally(() => { this.setState({ loading: false }) })
    }



    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout={"vertical"}
                    ref={this.refForm}
                    onFinish={this.onFinish}
                >
                    {this.state.direcciones.data.length > 0 ?
                        <Row gutter={[16, 16]} justify={'start'}>
                            <Col span={24}>
                                <Title level={5}>Dirección de Envio</Title>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="direccion_id"
                                >
                                    <Radio.Group
                                        name="direccion_selected"
                                        className='width-100'
                                        onChange={(e)=>{
                                            if(e.target)
                                                this.setState({continue: true})
                                        }}
                                    >
                                        {this.state.direcciones.data?.map(item => {
                                            return <Col span={24} className='width-100' style={{marginBottom: "1rem", borderBottom: "solid 1px #f5f5f5"}}>
                                                <Radio value={item._id} rootClassName='width-100'>
                                                    <div className="flex-left-column" >
                                                        <Text strong>{item.nombre}</Text>
                                                        <Text ellipsis>{item.direccion}, {item.ciudad}, {item.estado}, {item.pais}</Text>
                                                        <div className="width-100 flex-between">
                                                            <Text className="text-left">Código Postal: {item.codigo_postal}</Text>
                                                        </div>
                                                    </div>
                                                </Radio>
                                            </Col>
                                        })}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                        </Row >
                        : null
                    }

                </Form>

                <Row justifiy="center" className="mt-1">
                    <Col span={24}>
                        <Button
                            block
                            onClick={() => this.setState({ modal_visible: true })}
                        >Añadir Dirección</Button>
                    </Col>
                </Row>

                <Row justifiy="center" className="mt-2">
                    <Col span={24}>
                        <Button
                            loading={this.state.loading}
                            disabled={!this.state.continue}
                            type="primary"
                            block
                            onClick={() => {
                                this.refForm.current.submit()
                            }}
                        >Continuar</Button>
                    </Col>
                </Row>
                <ModalDirecciones
                    visible={this.state.modal_visible}
                    onClose={() => {
                        this.setState({ modal_visible: false })
                        this.getDirecciones()
                    }}
                />
            </Spin>
        )
    }
}


export default function Vista(props) {
    const setUser = useContext(SetUser)
    const user = useContext(User)
    const carrito = useContext(Carrito)

    return <StepAddress {...props} setUser={setUser} user={user} carrito={carrito} />

}