import React, { useState, useEffect, useContext} from 'react';
import { Badge, Button, Layout, Menu, Space, Row, Col, Dropdown, Typography } from 'antd';
import { UserOutlined, ShoppingOutlined } from '@ant-design/icons';
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';

//componentes
import DrawerCarrito from './Carrito/Drawer';
import Avatar from "../Widgets/Avatar/Avatar";
import { User, SetUser } from "../../Hooks/Logged";
import { Carrito, SetCarrito } from "../../Hooks/Carrito";
import { ArrowRightOutlined, DeleteOutlined, MenuOutlined, PhoneFilled, DownOutlined, ShoppingCartOutlined } from "@ant-design/icons";

//estilos
import "../../Styles/Modules/headers.scss"

const { Header } = Layout;
const { Text } = Typography;

/**
 * @const Header header publico de la landing page
 * @param {*} props 
 * @returns Component
 */
const MyHeader = (props) => {

    const navigate = useNavigate();

    let user = useContext(User);
    let setUser = React.useContext(SetUser)
    let carrito = React.useContext(Carrito)
    let setCart = React.useContext(SetCarrito)

    const [isScrolled, setIsScrolled] = useState(false);
    const [openCart, setOpenCart] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    /**
     * @const cerrarSesion
     * @description Cierra la sesion
     */
    const cerrarSesion = () => {
        setUser(undefined);
        sessionStorage.clear();
        navigate("/")
        setCart([])

        if (window.FB) {
            window.FB.logout(function (response) {
                console.log("response", response);
            });
        }

    };

    return (
        <>
            <Header className={`header-public ${isScrolled ? 'scrolled' : ''}`}>
                <Row className="main-header-container">
                    <Col xs={14} lg={5} className="flex-left">
                        <img className="brand" src="/img/logo.svg" onClick={()=>navigate("/")}/>
                    </Col>
                    <Col xs={0} lg={8} className="links">
                        <Link className="link" to={'/'}>Inicio</Link>
                        <Link className="link" to={'/productos'}>Productos</Link>
                    </Col>
                    <Col xs={0} lg={3} className="contact">
                        <a className="link" href={"https://wa.me/8121365636"} target={'_blank'}> <PhoneFilled /> 026-123-456</a>
                    </Col>
                    <Col xs={0} lg={6} className="center">
                        {user ? 
                            <Dropdown
                                arrow={true}
                                overlay={
                                    <Menu>
                                        <Menu.Item key="1" onClick={()=>navigate("/customer/pedidos")}>
                                            Mi Cuenta
                                        </Menu.Item>
                                        <Menu.Item key="0" onClick={cerrarSesion}>
                                            Cerrar Sesión
                                        </Menu.Item>
                                    </Menu>
                                } trigger={['click']}>
                                <div >
                                    <Text onClick={e => e.preventDefault()}
                                        style={{ marginLeft: '2rem' }}>
                                        {user?.nombre} <DownOutlined />
                                    </Text>
                                </div>
                            </Dropdown>
                         : <Button className="action-button" onClick={()=>navigate("/login")}>
                            Iniciar Sesión
                            <ArrowRightOutlined />
                        </Button>}
                    </Col>
                    <Col xs={2} lg={2} className="center">
                        <Badge count={carrito?.detalles?.length ?? 0}>
                            <Button className="action-button" onClick={() => setOpenCart(true)}>
                                <ShoppingCartOutlined style={{fontSize: "18px"}}/>
                            </Button>
                        </Badge>
                    </Col>
                    <Col xs={8} lg={0} className="mobile">
                        <Button className="menu">
                            <MenuOutlined />
                        </Button>
                    </Col>
                </Row>
            </Header>
            <DrawerCarrito
                open={openCart}
                onClose={()=>setOpenCart(false)}
                cart={carrito}
                setCart={setCart}
            />
        </>
    );
};

/**
 * @const Header header publico de la landing page
 * @param {*} props 
 * @returns Component
 */
const LandingHeader = (props) => {

    const navigate = useNavigate();

    let user = useContext(User);
    let setUser = React.useContext(SetUser)
    let carrito = React.useContext(Carrito)
    let setCart = React.useContext(SetCarrito)

    const [isScrolled, setIsScrolled] = useState(false);
    const [openCart, setOpenCart] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    /**
     * @const cerrarSesion
     * @description Cierra la sesion
     */
    const cerrarSesion = () => {
        setUser(undefined);
        sessionStorage.clear();
        navigate("/")
        setCart([])

        if (window.FB) {
            window.FB.logout(function (response) {
                console.log("response", response);
            });
        }

    };

    return (
        <>
            <nav className="main-navbar">
                <Row gutter={[36, 36]} className='wrapper' align={"middle"} justify={"space-between"}>
                    <Col xs={16} md={12} lg={12} xl={5}>
                        <img src="/img/landing/logo.svg" className='logo' />
                    </Col>
                    <Col xs={0} xl={14} className='links'>
                        <a href={"#header"} className='link'>Inicio</a>
                        <a href={"#about"} className='link'>Nosotros</a>
                        <a href={"#services"} className='link'>Servicios</a>
                        <Link to={'/productos'} className='link'>Productos</Link>
                        <a href={"#contact"} className='link'>Contacto</a>
                        <a href={"https://wa.me/+528119451744"} target={'_blank'} className='link'> <PhoneFilled /> +52 (811) 945-1744</a>
                    </Col>
                    <Col xs={8} md={12} lg={12} xl={5} className='actions'>
                    {user ? 
                        <Space>
                            <Dropdown
                                placement="bottomRight" 
                                className="mr-3"
                                trigger={['click']}
                                overlay={
                                    <Menu>
                                        <Menu.Item key="1" onClick={()=>navigate("/customer/pedidos")}>
                                            Mi Cuenta
                                        </Menu.Item>
                                        <Menu.Item key="0" onClick={cerrarSesion}>
                                            Cerrar Sesión
                                        </Menu.Item>
                                    </Menu>
                                } 
                            >
                                <div>
                                    <Avatar name={user?.nombre} size="large" />
                                </div>
                            </Dropdown>

                            <Badge count={carrito?.detalles?.length ?? 0}>
                                <Button className='actions-quote sm ml-2' >
                                    <ShoppingCartOutlined style={{fontSize: "22px"}} onClick={() => setOpenCart(true)}/>
                                </Button>
                            </Badge>
                        </Space>
                        : <a onClick={()=> navigate(`/login`)}  className='actions-quote'>
                            Iniciar sesión
                            <ArrowRightOutlined />
                        </a> }
                        <MenuOutlined
                            className="actions-menu"
                            onClick={() => this.setState({ menuOpen: true })}
                        />
                    </Col>
                </Row>
            </nav>
            <DrawerCarrito
                open={openCart}
                onClose={()=>setOpenCart(false)}
                cart={carrito}
                setCart={setCart}
            />
        </>
    );
};

export default MyHeader;

export {
    MyHeader,
    LandingHeader
}