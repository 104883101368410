import React, { Component, createRef, useRef, useContext } from 'react';
import { Carousel, Col, Divider, Drawer, Layout, Row, Typography, Menu, Dropdown, Badge, Button, Space } from 'antd';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { useNavigate } from 'react-router-dom';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import { ArrowRightOutlined, MenuOutlined, PhoneFilled, DownOutlined, ShoppingCartOutlined } from '@ant-design/icons';

// Components
import { Link } from 'react-router-dom';
import { LandingHeader } from "./Header";
import { User } from "../../Hooks/Logged";
import { Carrito } from "../../Hooks/Carrito";
import Avatar from "../Widgets/Avatar/Avatar";

import '../../Styles/Modules/Landing/landing.scss';


const { Title, Text, Paragraph } = Typography
const { Header, Content, Footer } = Layout

gsap.registerPlugin(useGSAP, ScrollTrigger)

const Animation = (props) => {

  useGSAP((context, contextSafe) => {
    // ELEMENTS
    const video = document.querySelector('#welcome .video video')
    const videoBtn = document.querySelector('#welcome .video .button')
    const navbar = document.querySelector('#overlay-navbar')

    // EVENTS
    // Navbar
    const navTween = gsap.fromTo(navbar, {
      y: '-100%',
      opacity: 0,
    }, {
      y: '0%',
      opacity: 1,
      duration: 0.5,
      paused: true
    })

    ScrollTrigger.create({
      start: 'top 300',
      end: 'max',
      onUpdate: self => {
        self.direction === 1 ? navTween.play() : navTween.reverse()
      }
    })

    // Video
    const toggleVideo = contextSafe(() => {
      if (video.paused) {
        gsap.to(videoBtn, {
          opacity: 0,
          display: 'none',
          scale: 0.5,
          duration: 0.5
        })
        video.play()
      } else {
        gsap.to(videoBtn, {
          opacity: 1,
          display: 'flex',
          scale: 1,
          duration: 0.5
        })
        video.pause()
      }
    })
    video.addEventListener('click', toggleVideo)
    videoBtn.addEventListener('click', toggleVideo)

    // EFFECTS
    const fadeUp = {
      from: {
        y: '25%',
        opacity: 0
      },
      to: {
        y: '0%',
        opacity: 1,
        duration: 0.7,
        stagger: 0.2
      }
    }
    const fadeDown = {
      from: {
        y: '-25%',
        opacity: 0
      },
      to: {
        y: '0%',
        opacity: 1,
        duration: 0.7,
        stagger: 0.2
      }
    }
    const fadeLeft = {
      from: {
        x: '-25%',
        opacity: 0
      },
      to: {
        x: '0%',
        opacity: 1,
        duration: 0.7,
        stagger: 0.2
      }
    }
    const fadeRight = {
      from: {
        x: '25%',
        opacity: 0
      },
      to: {
        x: '0%',
        opacity: 1,
        duration: 0.7,
        stagger: 0.2
      }
    }

    // ANIMATIONS

    // Header
    const header = gsap.timeline({ delay: 1 })
    header
      .fromTo('#header #navbar', fadeDown.from, fadeDown.to)
      .fromTo('#header #welcome .content', fadeLeft.from, fadeLeft.to, '<')
      .fromTo('#header #welcome .video video', fadeRight.from, fadeRight.to, '<');

    // Experience
    const experience = gsap.timeline({
      scrollTrigger: {
        trigger: '#header #welcome',
        start: 'bottom center',
        end: "+=100"
      }
    })
    experience
      .fromTo('#experience .data', fadeDown.from, fadeDown.to)

    // Services
    const services = gsap.timeline({
      scrollTrigger: {
        trigger: '#experience',
        start: 'bottom 400',
        end: "+=300"
      },
      delay: 0.5
    })
    services
      .fromTo('#services .bg .bg-orange', fadeRight.from, fadeRight.to)
      .fromTo('#services .image', fadeLeft.from, fadeLeft.to, '<')
      .fromTo('#services .article', fadeDown.from, fadeDown.to, '<')

    // Alcance
    const alcance = gsap.timeline({
      scrollTrigger: {
        trigger: '#services',
        start: 'bottom 400',
        end: "+=200"
      },
      delay: 0.5
    })
    alcance
      .fromTo('#alcance .gallery', fadeLeft.from, fadeLeft.to)
      .fromTo('#alcance .header', fadeRight.from, fadeRight.to, '<')

    // Tools
    const tools = gsap.timeline({
      scrollTrigger: {
        trigger: '#alcance',
        start: 'bottom 400',
        end: "+=200"
      },
      delay: 0.5
    })
    tools
      .fromTo('#tools .gallery .image', fadeDown.from, fadeDown.to)
      .fromTo('#tools .header', fadeRight.from, fadeRight.to, '<')

    // About
    const about = gsap.timeline({
      scrollTrigger: {
        trigger: '#tools',
        start: 'bottom 400',
        end: "+=400"
      },
      delay: 0.5
    })
    about
      .fromTo('#about .header', fadeDown.from, fadeDown.to)
      .fromTo('#about .content .card', fadeDown.from, fadeDown.to, '<')


    // CTA
    const cta = gsap.timeline({
      scrollTrigger: {
        trigger: '#about',
        start: 'center 400',
        end: "+=200"
      },
      delay: 0.5
    })
    cta
      .fromTo('#cta .left', fadeRight.from, fadeRight.to)
      .fromTo('#cta .right', fadeLeft.from, fadeLeft.to, '<')


    // About
    const contact = gsap.timeline({
      scrollTrigger: {
        trigger: '#cta',
        start: 'bottom 80%',
        end: "+=100"
      },
      delay: 0.5
    })
    contact
      .fromTo('#contact .wrapper .ant-col', fadeDown.from, fadeDown.to)

    return () => {
      //Video
      video.removeEventListener('click', toggleVideo)
      videoBtn.removeEventListener('click', toggleVideo)
    }

  })

  return props.children
}

/**
 * 
 * @class Landing
 * @extends {Component}
 * @description Componente Landing page
 */
class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sections: []
    }
  }

  carouselHeader = createRef(null)


  toggleVideo = (btn, video) => {
    return () => {
      console.log(btn.classList, video.paused)
      btn.classList.toggle('hide')
      video.paused ? video.play() : video.pause()
    }
  }

  /**
     * @const cerrarSesion
     * @description Cierra la sesion
     */
  cerrarSesion = () => {
    this.props.setUser(undefined);
    sessionStorage.clear();
    this.props.navigate("/")
    this.props.setCart({})

    if (window.FB) {
        window.FB.logout(function (response) {
            console.log("response", response);
        });
    }

  };

  render() {

    const { user, navigate } = this.props;

    const carouselSettings = {
      infinite: false,
      slidesToShow: 3,
      responsive: [{
        breakpoint: 1300,
        settings: {
          slidesToShow: 2
        }
      }, {
        breakpoint: 900,
        settings: {
          slidesToShow: 1
        }
      }, {
        breakpoint: 650,
        settings: {
          slidesToShow: 1
        }
      }]
    }

    const industrias = [{
      name: 'Industrial',
      icon: 'industrial',
      desc: 'Proporcionamos soluciones avanzadas para sistemas de automatización y maquinaria en procesos industriales.'
    }, {
      name: 'Agrícola',
      icon: 'agricola',
      desc: 'Ofrecemos equipos y sistemas hidráulicos que optimizan las operaciones agrícolas, desde la irrigación hasta la maquinaria de cosecha.'
    }, {
      name: 'Moviles',
      icon: 'moviles',
      desc: 'Suministramos componentes y sistemas para vehículos de transporte, mejorando la logística y mantenimiento de flotas.'
    }, {
      name: 'Forestal',
      icon: 'forestal',
      desc: 'Brindamos soluciones en maquinaria y equipos para la gestión forestal, aumentando la eficiencia en la explotación de recursos madereros.'
    }, {
      name: 'Petroleo y Gas',
      icon: 'petroleo-gas',
      desc: 'Proveemos tecnología y componentes para la extracción y procesamiento en la industria del petróleo y gas.'
    }, {
      name: 'Navales',
      icon: 'navales',
      desc: 'Ofrecemos soluciones para la industria naval, incluyendo sistemas hidráulicos para barcos y equipos de mantenimiento marítimo.'
    }, {
      name: 'Minería',
      icon: 'mineria',
      desc: 'Suministramos equipos robustos y confiables para la minería, facilitando la extracción y el procesamiento de minerales.'
    }, {
      name: 'Energia',
      icon: 'energia',
      desc: 'Proporcionamos componentes esenciales para la generación y distribución de energía, incluyendo el sector de energías renovables.'
    }]

    return (
      <div style={{position: "relative"}}>
        <LandingHeader/>
        <Animation>
          <Layout id="landing">
            <header id="header" >
              <section id="welcome">
                <div className='content'>
                  <div className="wrapper">
                    <Text className='subtitle'>
                      ESPECIALISTAS EN MAQUINARIA Y MATERIAL HIDRAULICO
                    </Text>
                    <Title level={1} className='title'>
                      Mantenimiento, Fabricación, Reparación de Cilindros, Válvulas y maquinados especializados.
                    </Title>
                    <Text className='description'>
                      Reparación de Cilindros Hidráulicos y Neumáticos para servicio pesado, liviano y especiales.
                    </Text>
                    <div className='links'>
                      <Link to="mailto:udnmty@dicofansa.com?subject=%C2%A1Quiero%20solicitar%20una%20cotizaci%C3%B3n!" className='link'>
                        Obtener cotizacion
                      </Link>
                      <Link to="#" className='link'>
                        Sobre Nosotros
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="video">
                  <video loop={true} autoPlay muted>
                    <source src='/video/placeholder.mp4' type="video/mp4" />
                  </video>
                  <button className='button'>
                    <div>
                      <img src="/img/landing/play.svg" />
                    </div>
                  </button>
                </div>
              </section>

            </header>
            <main className='landing-main main'>
              <section id='experience'>
                <Row gutter={[12, 12]} justify={"center"} className='wrapper'>
                  <Col xs={24} lg={7} className='data'>
                    <Text className='number'>400+</Text>
                    <Text className='type'>Productos</Text>
                    <Text className='subtype'>Especializados</Text>
                  </Col>
                  <Col xs={12} lg={1} className='div'>
                    <Divider className='div-vertical' type="vertical" />
                    <Divider className='div-horizontal' type="horizontal" />
                  </Col>
                  <Col xs={24} lg={8} className='data'>
                    <Text className='number'>200+</Text>
                    <Text className='type'>Ventas</Text>
                    <Text className='subtype'>de Producto</Text>
                  </Col>
                  <Col xs={12} lg={1} className='div'>
                    <Divider className='div-vertical' type="vertical" />
                    <Divider className='div-horizontal' type="horizontal" />
                  </Col>
                  <Col xs={24} lg={7} className='data'>
                    <Text className='number'>100+</Text>
                    <Text className='type'>Años</Text>
                    <Text className='subtype'>de Experiencia</Text>
                  </Col>
                </Row>
              </section>
              <section id="services">
                <Row className='bg'>
                  <Col xs={24} lg={8}>
                  </Col>
                  <Col xs={24} lg={16} className='bg-orange'>
                  </Col>
                </Row>
                <Row className='content'>
                  <Col xs={24} lg={11} xl={12} className="image">
                    <img src="/img/landing/wwd.png" className='thumb' />
                  </Col>
                  <Col xs={24} lg={13} xl={12} className="article">
                    <Text className='subtitle'>LO QUE HACEMOS</Text>
                    <Title level={2} className='title'>Proveer soluciones de calidad garantizada.</Title>
                    <Paragraph>
                      Ofrecemos el servicio para fabricar, reparar, dar mantenimiento a cilindros hidráulicos y neumáticos, así como válvulas de descascarado y horneado de camisas a las empresas locales dedicadas del sector del acero, minero y equipo móvil, tales como montacargas, trackmovil, etc.
                    </Paragraph>
                    <blockquote>
                      Proporcionar un servicio de calidad aplicando los materiales adecuados mediante supervisión de Ingeniería que satisfaga las necesidades de nuestro cliente de acuerdo al medio de operación de cada uno de sus ensambles.
                    </blockquote>
                    <Paragraph>
                      Lo anterior garantiza que nuestro servicio cumpla con su requerimiento. Ofrecemos a su disposición la experiencia de 20 años en el mercado dando servicio a las diferentes empresas tanto locales como foráneas.
                    </Paragraph>
                  </Col>
                </Row>
              </section>
              <section id={'alcance'} >
                <Row gutter={[80, 80]} className='wrapper' align={'middle'}>
                  <Col xs={24} lg={12} className='gallery'>
                    <img className='image' src="/img/landing/valves.jpg" />
                  </Col>
                  <Col xs={24} lg={12}>
                    <div className='header'>
                      <Text className='subtitle'>NUESTRO ALCANCE</Text>
                      <Title className='title' level={2}>Mantenimiento, Fabricación, Reparación de Cilindros, Válvulas y maquinados especializados.</Title>
                      <div className='description'>
                        <p>
                          Ofrecemos a la industria local los servicios principalmente en Reparación de Cilindros Hidráulicos y Neumáticos para servicio pesado, liviano y especiales.
                          <br />
                          Durante nuestra trayectoria hemos trabajado con las principales empresas en el área metropolitana, y foráneas Importantes.
                        </p>
                        <ul>
                          <li>Prolec SA de CV</li>
                          <li>Prolec Ge International SA de CV</li>
                          <li>Productos Laminados de Monterrey, SA de CV</li>
                          <li>Con Met de México SA de CV</li>
                          <li>Vesuvius México SA de CV</li>
                          <li>Compañía Minera Autlán SA de CV</li>
                          <li>Melter SA de CV</li>
                          <li>MAK Suministros y Aplicaciones SA de CV</li>
                          <li>Graftech Mexico SA de CV</li>
                          <li>Altos Hornos de México SA de CV</li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </section>
              <section id={'tools'}>
                <Row gutter={[80, 80]} className='wrapper' align={'middle'}>
                  <Col xs={24} xl={12}>
                    <div className='header'>
                      <Text className='subtitle'>LO QUE TENEMOS</Text>
                      <Title className='title' level={2}>Taller e infraestructura para nuestras soluciones</Title>
                      <Paragraph className='description'>
                        Honeadora desde ø3” a ø20” y 6 mts de largo, Tornos diferentes capacidades, Fresadoras, Unidad hidráulica, Prensa Hidráulica, Equipo de transporte para el traslado de sus ensambles.
                        <br />
                        Los servicios ofrecidos cumplen plenamente con las necesidades de nuestros clientes ya que contamos con un Departamento de Ingeniería que verifica daños a detalle de cada uno de los elementos mecánicos.
                        <br />
                        Asimismo se entregan debidamente probados al terminar el servicio. Para seguridad y satisfaciendo las necesidades del cliente, extendemos Certificado de Calidad y Carta de Garantía en cada servicio.
                      </Paragraph>
                    </div>
                  </Col>
                  <Col xs={24} xl={12} className='gallery'>
                    <img className='image' src="/img/mangeras.png" />
                    <img className='image' src="/img/presentacion.png" />
                    <img className='image' src="/img/manufacturer.png" />
                  </Col>
                </Row>
              </section>
              <section id="about">
                <div className='header'>
                  <Text className='subtitle'>NOSOTROS</Text>
                  <Title className='title' level={2}>Alta Calidad en Productos Industriales</Title>
                  <Paragraph className='description'>
                    DICOFANSA ofrece servicios de alta calidad en instalaciones Oleo-Hidráulicas e Hidráulicas, sistemas de lubricación, y manejo de Hidrocarburos. Somos líderes en proporcionar soluciones costo-efectivas para la Industria Siderúrgica, Minera, Cementera, Papelera, Textil, Automotriz, y más. Además, somos proveedores clave de productos y repuestos
                  </Paragraph>
                </div>
                <Row className='content' gutter={[30, 30]} justify={"center"}>
                  {
                    industrias.map(({ name, icon, desc }, i) => (
                      <Col xs={24} sm={12} lg={8} xl={6} key={`${icon}-${i}`}>
                        <div className='card' >
                          <Text className='card-title'>{name}</Text>
                          <img className='card-icon' src={`/img/landing/industrias/${icon}.svg`} />
                          <Paragraph className='card-desc'>
                            {desc}
                          </Paragraph>
                        </div>
                      </Col>
                    ))
                  }
                </Row>
              </section>

              <section id="cta">
                <Row gutter={[24, 24]} className='wrapper' align={"middle"}>
                  <Col xs={24} md={12} className='left'>
                    <Text className='title'>¿Quieres más información?</Text> <br />
                    <Text className='subtitle'>Contactanos a través de nuestra información</Text>
                  </Col>
                  <Col xs={24} md={12} className='right'>
                    <a to={"https://wa.me/+528119451744"} target='_blank' className='button' >
                      Comunícate con nosotros
                    </a>
                  </Col>
                </Row>
              </section>
            </main>
            <footer id="contact">
              <Row className='wrapper' gutter={[40, 40]} justify={"space-between"}>
                <Col xs={24} lg={6} className='about' >
                  <img src="/img/landing/logo.svg" className='about-logo' />
                  <Paragraph className='about-description'>
                    The process of adding structures to areas of land, also known as real property sites.
                  </Paragraph>
                  <nav className='about-socials'>
                    <a href="https://www.linkedin.com/in/dicofansa-s-a-de-cv-031b0b68/" target='_blank'>
                      <img src="/img/landing/social/linkedin.svg" />
                    </a>
                  </nav>
                </Col>
                <Col xs={24} sm={12} lg={8} className='offices'>
                  <Text className='title'>Sucursales</Text>
                  <div className='offices-list'>
                    <Paragraph>
                      Indonesia <br />
                      Jl. Griya Permata Hijau no D1 Purwomartani Kalasan 543881
                    </Paragraph>
                  </div>
                </Col>
              </Row>
            </footer>
            {/*<nav id="overlay-navbar">
              <Row gutter={[36, 36]} className='wrapper' align={"middle"} justify={"space-between"}>
                <Col xs={16} md={12} lg={12} xl={5}>
                  <img src="/img/landing/logo.svg" className='logo' />
                </Col>
                <Col xs={0} xl={14} className='links'>
                  <a href={"#header"} className='link'>Inicio</a>
                  <a href={"#about"} className='link'>Nosotros</a>
                  <a href={"#services"} className='link'>Servicios</a>
                  <Link to={'/productos'} className='link'>Productos</Link>
                  <a href={"#contact"} className='link'>Contacto</a>
                  <a href={"https://wa.me/+528119451744"} target={'_blank'} className='link'> <PhoneFilled /> +52 (811) 945-1744</a>
                </Col>
                <Col xs={8} md={12} lg={12} xl={5} className='actions'>
                  <a onClick={()=>this.props.navigate(`/login`)}  className='actions-quote'>
                    Iniciar sesión
                    <ArrowRightOutlined />
                  </a>
                  <MenuOutlined
                    className="actions-menu"
                    onClick={() => this.setState({ menuOpen: true })}
                  />
                </Col>
              </Row>
            </nav>*/}
            <Drawer
              open={this.state.menuOpen}
              onClose={() => this.setState({ menuOpen: false })}
              id="overlay-menu"
              placement='left'
              title={null}
              closeIcon={null}
              width={220}
            >
              <nav className='menu-links'>
                <Link to={"#"} className='menu-link'>Inicio</Link>
                <Link to={"#about"} className='menu-link'>Nosotros</Link>
                <Link to={"#services"} className='menu-link'>Servicios</Link>
                {/* <Link to={"#"} className='menu-link'>Productos</Link> */}
                <Link to={"#contact"} className='menu-link'>Contacto</Link>
                <Link to="https://wa.me/+528119451744" className='menu-link'> <PhoneFilled /> +52 (811) 945-1744</Link>
              </nav>
              <a target='_blank' href="mailto:udnmty@dicofansa.com?subject=%C2%A1Quiero%20solicitar%20una%20cotizaci%C3%B3n!" className='menu-quote'>
                Obtener una cotizacion
                <ArrowRightOutlined />
              </a>
            </Drawer>
          </Layout>
        </Animation>
      </div>
      

    )
  }
}


export default function (props) {

  let navigate = useNavigate()
  let user = useContext(User);
  let carrito = useContext(Carrito);

  return <Landing {...props} navigate={navigate} user={user} carrito={carrito}/>
}