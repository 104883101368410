import React from 'react';
import { Card, Row, Col, Avatar, Typography, Button, Space } from 'antd';
import { DeleteOutlined } from "@ant-design/icons"
const { Text, Paragraph } = Typography;



const renderImage = (id, images, model) => {
	if (images && images.length > 0)
		return `${process.env.REACT_APP_WEB_SERVICE}/public/${model}/${id}?imagen=${images[0]?.file}`
	return "./img/no-image.png"
}

/**
 *
 *
 * @param {*} { item, cantidad, onDelete }
 * @returns 
 */
function CartElement({ item, cantidad, onDelete }) {

	let { producto, subtotal } = item;

	let { nombre, descripcion, imagenes, model, _id, precio_venta } = producto;


	return (
		<Card className='width-100'>
			<Row gutter={[8, 0]} justify={"space-between"}>
				<Col span={4}>
					<Avatar shape='square' size={50}
						src={renderImage(_id, imagenes, "productos")}
						alt={nombre} />
				</Col>
				<Col span={10}>
					<Space direction="vertical" size={"small"}>
						<Text strong>{nombre}</Text>
						{/*<Text >{variante_id?.nombre}</Text>*/}
						<Text ellipsis className='text-gray'>{descripcion}</Text>
						<Text type="secondary">{precio_venta ? `$ ${precio_venta}` : null}</Text>
					</Space>
				</Col>
				<Col span={4} className='center'>
					<Text type="secondary">{cantidad}</Text>
				</Col>
				<Col span={4} className='center'>
					<Text type="secondary">{subtotal ? `$ ${subtotal.toMoney(true)}` : null}</Text>
				</Col>
				<Col span={2} className='center'>
					<Button type="primary" size='small' danger icon={<DeleteOutlined />} onClick={() => onDelete(_id)}></Button>
				</Col>
			</Row>
		</Card>
	);
}

export default CartElement;