import React, { Component, useState, useContext } from "react";
import { Drawer, List, message, Row, Col, Typography, Divider, Button } from "antd";
import axios from "axios";
import { useCookies } from "react-cookie";


//componentes
import CardItem from "./CartItem";
import StepLogin from "./StepLogin";
import StepAddress from "./StepAddress";
import StepPayment from "./StepPayment";
import { User, SetUser } from "../../../Hooks/Logged";



import '../../../Styles/Modules/cart.scss'

class Cart extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    /**
     * @memberof Cart
     * @descripcion Elimina un producto del carrito de compra
     */
    deleteItem = (producto_id) => {

        this.setState({loading: true})
        axios.delete('/carrito', {
            params: {
                producto_id: producto_id
            }
        }).then(({ data }) => {
            console.log('se trajo carrito', data)
            this.props.setCart(data)
        }).catch((error) => {
            console.log(error)
            message.error(error?.response?.data?.message ?? "Error al eliminar el producto")
        }).finally(()=>this.setState({loading: false}))
    }


    render() {
        return <>
            <List
                loading={this.state.loading}
                className="cart"
                dataSource={Array.isArray(this.props.cart?.detalles) ? this.props.cart?.detalles : []}
                locale={{ emptyText: "Tu carrito esta vacio" }}
                header={
                    <>
                        <Row >
                            <Col span={15} className="">Productos</Col>
                            <Col span={3} className="center">Cant.</Col>
                            <Col span={3}  className="center">Monto</Col>
                        </Row>
                    </>
                }
                footer={
                    <>
                        {/*<Row align={"center"} >
                            <Col span={6} className="cart-resume-text">Subtotal</Col>
                            <Col span={18} className="cart-resume-amount">{(this.props.cart.subtotal ?? 0).toMoney(true)}</Col>
                        </Row>*/}
                        {/* <Row align={"center"} >
                            <Col span={6} className="cart-resume-text">Taxes</Col>
                            <Col span={18} className="cart-resume-amount">{(this.props.cart.tax ?? 0).toMoney(true)}</Col>
                        </Row> */}
                        <Row align={"center"} >
                            <Col span={6} className="cart-resume-text">Total</Col>
                            <Col span={18} className="cart-resume-amount">{(this.props.cart.subtotal ?? 0 + this.props.cart.tax ?? 0).toMoney(true)}</Col>
                        </Row>
                        <Row align={"center"} className="mt-2">
                            <Button
                                type="primary"
                                block
                                onClick={() => {
                                    if(this.props.cart.total > 0)
                                        this.props.next()

                                }}
                            >Continuar</Button>
                        </Row>
                    </>
                }
                renderItem={item => <List.Item>
                    <CardItem 
                        item={item} 
                        cantidad={item.cantidad} 
                        onDelete={this.deleteItem} 
                    />
                </List.Item>}
            />
        </>
    }
}



export default function DrawerCarrito(props) {


    let user = useContext(User)
    const [step, setStep] = useState(0);
    const [cookies, setCookie] = useCookies(['cart']);

    let next = () => {
        setStep(step + 1)
    }
   


    return <Drawer
        className="cart"
        open={props.open}
        onClose={()=>{
            props.onClose()
            setStep(0)
        }}
        destroyOnClose={true}
        title="Carrito de Compra"
        width={800}
    >
        {step === 0 && <Cart {...props} next={next} user={user}/>}
        {step === 1 && <StepAddress {...props} next={next} user={user}/>}
        {step === 2 && <StepPayment {...props} next={next} user={user} setCookie={setCookie} setStep={setStep}/>}

    </Drawer>

}