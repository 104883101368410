import React, { Component } from 'react';
import { Row, Col, Typography, Spin, Layout, Space, message, Card, List, Modal, Collapse, Button,  } from 'antd'
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { CopyOutlined, WarningFilled } from '@ant-design/icons';


//componentes
import ModalCSV from './ModalCSV';
import ModalImagenes from './ModalImagenes';
import ModalProductos from './ModalProductos'
import { getResponseError } from '../../Utils';
import useSearch from '../../../Hooks/useSearch';
import Logged, { User } from "../../../Hooks/Logged";
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import usePermissions from '../../../Hooks/usePermissions';
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import DrawerFiltros from '../../Widgets/DrawerFiltros/DrawerFiltros';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import { ButtonDelete, ButtonEdit, ButtonView } from '../../Widgets/Buttons';

import '../../../Styles/Modules/Admin/productos.scss';

const { Content } = Layout;
const { Text } = Typography
const { confirm } = Modal

/**
 *
 *
 * @export
 * @class Productos
 * @extends {Component}
 * @description Vista donde se listan todos los productos
 */
class Productos extends Component {
    static contextType = Logged;

    back = () => {
        this.props.history.goBack();
    }


    /**
     *Creates an instance of Productos.
     * @param {*} props
     * @memberof Productos
     */
    constructor(props) {
        super(props)
        this.state = {

            loading: false,
            productos: {
                data: [],
                limit: 20,
                page: 1,
                total: 0,
                pages: 0,
                filters: []
            },
            producto_id: null
        }
    }

    componentDidMount() {
        this.props.setShowSearch(true)

        this.getProductos()
    }

    componentDidUpdate(prevProps) {
        if (this.props.search != prevProps.search) {
            this.getProductos()
        }
    }

    /**
    *
    * @memberof Productos
    * @method getProductos
    * @description Obtiene la lista de productos
    */
    getProductos = async ({
        page = this.state.productos.page,
        limit = this.state.productos.limit,
        search = this.props.search,
        filters = this.state.productos.filters,
    } = this.state.productos) => {

        this.setState({ loading: true })
        axios.get('/productos', {
            params: {
                limit,
                page,
                search,
                filters,
                //groupSKU: true
            }
        })
            .then(({ data }) => {
                console.log("dassta", data);
                console.log("filters", filters);
                this.setState({ productos: {...data, filters} })
            })
            .catch(res => {
                console.log("res", res);
                message.error(res.response?.data?.message ?? "Error")
            })
            .finally(() => this.setState({ loading: false }))
    }


    /**
    *
    * @memberof Productos
    * @method downloadCSV
    * @description Obtiene un excel con los productos filtrados
    */
    downloadCSV = () => {
        let url = new URL(axios.defaults.baseURL + '/productos-csv')
        url.searchParams.append('Authorization', sessionStorage.getItem('token'))
        if(this.state?.productos?.filters?.length > 0){
            let filters = JSON.parse(JSON.stringify(this.state?.productos?.filters));
            url.searchParams.append('filters', JSON.stringify(filters))
        }
        if(this.props.search) url.searchParams.append('search', this.props.search)
        url.searchParams.append('csv', true)
        window.open(url, '_blank')
    }


    render() {

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="admin-page-header"
                        title="Productos"
                        extra={<Space>
                            <Button onClick={()=>this.setState({ modal_imagenes: true })}>Añadir Imagenes</Button>
                            <Button onClick={()=>this.setState({ drawer_visible: true })}>Filtros</Button>
                        </Space>}
                    >

                    </PageHeader>

                    <Content className="admin-content content-bg pd-1">
                        <List
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin productos" }}
                            dataSource={this.state.productos.data}
                            pagination={{
                                current: this.state.productos.page,
                                pageSize: this.state.productos.limit,
                                total: this.state.productos.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                onChange: (page, limit) => this.getProductos({ page, limit })

                            }}
                            header={
                                <Row className="component-list-header width-100 pr-1 pt-1 pl-1" >
                                    <Col span={1}></Col>
                                    <Col span={5} className="center">
                                        <Text strong>Nombre</Text>
                                    </Col>
                                    <Col span={4} className="center">
                                        <Text strong>Modelo</Text>
                                    </Col>
                                    <Col span={5} className="center">
                                        <Text strong>Marca</Text>
                                    </Col>
                                    <Col span={5} className="center">
                                        <Text strong>SKU</Text>
                                    </Col>

                                </Row>
                            }
                            renderItem={producto => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100" gutter={[8, 8]}>
                                            <Col xs={1}>
                                                <CustomAvatar
                                                    {...(producto.imagenes?.length > 0 ?
                                                        { image: axios.defaults.baseURL + "/productos/" + producto._id + "?imagen=" + producto.imagenes[0].file + "&Authorization=" + sessionStorage.getItem("token") }
                                                        : { name: producto.nombre }
                                                    )}
                                                />
                                            </Col>
                                            <Col xs={5} className="center">
                                                <Text ellipsis>{producto.nombre ?? "-"}</Text>
                                            </Col>
                                            <Col xs={4} className="center">
                                                <Text ellipsis>{producto.modelo ?? "-"}</Text>
                                            </Col>
                                            <Col xs={5} className="center">
                                                <Text ellipsis>{producto.marca ?? "-"}</Text>
                                            </Col>
                                            <Col xs={5} className="center">
                                                <Text ellipsis>{producto.sku ?? "-"}</Text>
                                            </Col>
                                            <Col xs={4} className="flex-right">
                                                <Space>
                                                    <ButtonEdit
                                                        disabled={!this.props.canEdit}
                                                        onClick={() => this.setState({ modalProductos: true, producto_id: producto._id })}
                                                    />
                                                    <ButtonDelete
                                                        disabled={!this.props.canDelete}
                                                        onConfirm={
                                                            () => confirm({
                                                                title: "¿Quieres eliminar este producto?",
                                                                icon: <WarningFilled />,
                                                                content: "Todo lo relacionado a él será eliminado",
                                                                okText: "Eliminar",
                                                                cancelText: "Cancelar",
                                                                onOk: () => axios.delete('/productos/', { params: { id: producto._id } })
                                                                    .then((response) => {
                                                                        message.success("Eliminado correctamente")
                                                                        this.getProductos()
                                                                    })
                                                                    .catch((error) => {
                                                                        message.error(getResponseError(error.response, "Error al eliminar"));
                                                                        this.getProductos()
                                                                    }),
                                                                onCancel: () => { }
                                                            })
                                                        } />

                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Content>

                    {this.props.canCreate && <>
                        <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalProductos: true })} />
                        <FloatingButton
                            buttonStyle={{
                                width: "40px",
                                height: "40px",
                                bottom: "42px",
                                right: "125px",
                            }}
                            icon={<CopyOutlined style={{ color: "white", fontSize: "20px", left: -6, top: 2, position: "relative" }} />}
                            title="Crear productos por CSV"
                            onClick={() => this.setState({ modalCSV: true })}

                        />
                    </>}


                    <ModalProductos
                        visible={this.state.modalProductos}
                        producto_id={this.state.producto_id}
                        onClose={() => {
                            this.setState({ modalProductos: false, producto_id: undefined })
                            this.getProductos()
                        }}
                    />

                    <ModalCSV
                        visible={this.state.modalCSV}
                        onClose={() => {
                            this.setState({
                                modalCSV: false,
                            })
                        }}

                        onSuccess={(closeModal) => {
                            this.getProductos();

                            if (closeModal)
                                this.setState({ modalCSV: false, })
                        }}
                    />

                    <ModalImagenes
                        visible={this.state.modal_imagenes}
                        onClose={()=>this.setState({modal_imagenes: false})}
                    />

                    <DrawerFiltros
                        visible={this.state.drawer_visible}
                        onClose={()=>this.setState({drawer_visible: false})}
                        categorias={true}
                        updateFilters={(filtros)=>{
                            console.log("filtros", filtros);
                            this.getProductos({ filters: filtros, page: 1 })
                        }}
                        downloadCSV={this.downloadCSV}
                    />
                </Spin>
            </>
        )
    }
}

export default function (props) {
    const { setShow } = useSearch()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canEdit: ['productos', 'edit'],
        canDelete: ['productos', 'delete'],
        canCreate: ['productos', 'create']
    })

    return <Productos {...props} setShowSearch={setShow} {...permisos} navigate={useNavigate()} />
}