import React from 'react'
import { Route, Routes } from "react-router-dom";
import Pedidos from '../../Components/Customer/Pedidos/Pedidos'


/**
 * @export
 * @function RouterPedidios
 * @extends {Component}
 */
function RouterPedidios(props) {

    

    return (
        <Routes>
            <Route path="" element={<Pedidos {...props} />} />
        </Routes>
    )
}

export default RouterPedidios;
