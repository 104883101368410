
import React, {useState} from "react";
import {Avatar} from "antd";
import axios from "axios";


export default function AvatarProductos(props){

    let {
    	producto_id,
        imagenes = [] ,
        shape="square",
        size=64,
    } = props;


    let src = "/img/no-image.png";

    if(imagenes.length > 0){
        if(imagenes[0].file) src = axios.defaults.baseURL + '/public/productos/' + producto_id + "?imagen=" + imagenes[0].file
        if(imagenes[0].url) src = imagenes[0].url
    }
     


    return <Avatar
        shape={shape}
        size={size}
        src={src}
    />

}


