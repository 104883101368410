import React, { Component, useContext } from 'react';
import { Form, Input, Button, Row, Col, message, Tabs, Spin, List, Typography, Space, Card, Modal, Tag, Layout } from 'antd';
import axios from "axios";

//COMPONENTES
import ModalPedidoDetalle from "./ModalPedidoDetalle"
import { SetUser } from '../../../Hooks/Logged';
import { SetCarrito } from '../../../Hooks/Carrito';
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import AvatarProductos from '../../Widgets/AvatarProductos';

const { Content } = Layout;
const { Title, Text } = Typography;
const moment = require("moment")

/**
 * @class Pedidos
 * @description Vista principal donde el cliente ve los pedidos realizados
 */
class Pedidos extends Component {


	constructor(props){
		super(props)
        this.state = {
        	ventas: {
        		data: [],
        		limit: 10,
        		page: 1,
        		total: 0,
        	}
        }
	}

	componentDidMount(){
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getPedidos();
	}

	formRef = React.createRef();


	/**
     *
     *
     * @param {*} [page=this.state.page] Numero de la pagina a obtener
     * @memberof Compras
     * @method getPedidos
     * @description Obtiene el listado de areas
     */
    getPedidos = ({
    	page = this.state.ventas.page,
    	limit = this.state.ventas.limit,
    } = this.state.ventas) => {
        let search = this.props.search;
        this.setState({loading: true})
        axios.get('/customer/ventas', {
            params: {
                page: page,
                limit:limit
            }
        }).then(response => {
        	this.setState({
        		ventas: response.data
        	})
            

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(()=>this.setState({loading: false}))
    }


    /**
     * @memberof Productos
     * @description añade el producto al carrito de compra del ecommerce
     */
    addItem = (producto_id) => {

        this.setState({loading: true})
        axios.post('/carrito',{
            producto_id,
        }).then(({data})=>{
            if(this.props.setCarrito){
                this.props.setCarrito(data.carrito)
            }
            message.success("Producto agregado con exito")
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al añadir el producto al carrito")

        }).finally(()=>this.setState({loading: false}))
    }


	render() {

		let { ventas } = this.state;

		return (
			<>
				<PageHeader
                    className="admin-page-header"
                    title="Mis Pedidos"
                >
                </PageHeader>
                <Content className="admin-content content-bg pd-1">
	                <List
	                    loading={this.state.loading}
	                    className="component-list"
	                    itemLayout="horizontal"
	                    locale={{ emptyText: "Sin Pedidos " }}
	                    dataSource={ventas.data}
	                    pagination={{
	                        onChange: (page, limit) => this.getPedidos({ page }),
	                        current: ventas.page,
	                        pageSize: ventas.limit,
	                        total: ventas.total,
	                        hideOnSinglePage: false,
	                        position: 'bottom',
	                        className: "flex-left"
	                    }}
	                    renderItem={item => (
	                        <List.Item className="component-list-item" style={{display: "flex", justifyContent: "center"}}>
	                            <Card
	                                style={{maxWidth: "1000px"}}
	                                title={
	                                    <Row className="pt-1">
	                                        <Col span={6}>
	                                            <Space.Compact direction="vertical">
	                                                <small> Folio </small>
	                                                <Text> {item.folio} </Text>
	                                            </Space.Compact>
	                                        </Col>
	                                        <Col span={6}>
	                                            <Space.Compact direction="vertical">
	                                                <small> Total </small>
	                                                <Text> $ {item.total.toMoney(true)} MXN</Text>
	                                            </Space.Compact>
	                                        </Col>
	                                        <Col span={6}>
	                                            <Space.Compact direction="vertical">
	                                                <small> Fecha de compra </small>
	                                                <Text> {moment(item.createdAt).format("DD MMMM YYYY")} </Text>
	                                            </Space.Compact>
	                                        </Col>
	                                        <Col span={6} className="flex-right">
	                                            <Button type="primary" className="label-action-gray" onClick={()=>this.setState({modalVisible: true, venta_id: item._id})}>Ver detalles</Button>
	                                        </Col>
	                                        <Col span={24} className="mt-1">
	                                            Enlace Guía de Envío: {item.enlace_guia_envio ? <a href={item.enlace_guia_envio} target="_blank" style={{fontWeight: "bold"}}>{item.enlace_guia_envio}</a> : <Tag color="red">Pendiente</Tag>}
	                                        </Col>
	                                    </Row>
	                                }
	                                className="card-list"
	                            >
	                                { item.detalles.map(item_venta => <Row gutter={[12,12]}className="width-100 mb-1" key={item_venta._id}>
	                                    <Col className="">
	                                        <AvatarProductos imagenes={item_venta.producto.imagenes} size={94} producto_id={item_venta?.producto?._id}/>
	                                    </Col>
	                                    <Col flex="auto" className="">
	                                        <Space.Compact direction="vertical">
	                                            <Text>{item_venta?.producto?.descripcion}</Text>
	                                            <small>{item_venta?.producto?.marca}</small>
	                                            <Text>Cantidad: {item_venta?.cantidad}</Text>
	                                        </Space.Compact>
	                                    </Col>
	                                    <Col className="center">
	                                        <Button onClick={()=>this.addItem(item_venta.producto._id)}>Volver a comprar</Button>
	                                    </Col>
	                                </Row>) }
	                            </Card>
	                        </List.Item>
	                    )}
	                />
	            </Content>
	            <ModalPedidoDetalle
	            	visible={this.state.modalVisible}
                    onClose={()=>this.setState({
                        modalVisible: false, venta_id: undefined
                    })}
                    venta_id={this.state.venta_id}
	            />
			</>
		);
	}
}

export default function (props) {

    const setUser = useContext(SetUser)
    const setCarrito = useContext(SetCarrito)

    return <Pedidos 
        {...props}
        setUser={setUser}
        setCarrito={setCarrito}
    />
}