import React, { useState } from 'react';
import { Card, Row, Col, Typography, Button, Space, Form, Image, message  } from 'antd'
import axios from 'axios';
import '../../Styles/Global/cards.css'
import './cards.css'
import { ButtonDelete,  ButtonEdit, } from "./Buttons"
import { FaArrowRight } from 'react-icons/fa'
import { FiArrowDownRight, FiArrowUpRight } from 'react-icons/fi'
import { EditFilled, DeleteFilled } from '@ant-design/icons';

import ProductControl from './ProductControl/ProductControl';


const { Text, Title, Paragraph } = Typography;


/**
 *
 *
 * @const CardStatistics
 * @extends {React.Component}
 * @description Card para estadisticas del dashboard principal
 */
const CardStatistics = ({ monto = 0, icon, color = "#EE492A", title, suffix, onClick, percent }) => {

    let aumento = percent !== undefined && !(percent < 0)

    return <Card size="small" className="card-statistics">
        <Row align={"middle"} className="card-header">
            <Col flex="auto">
                <Text className="card-title">{title}</Text>
            </Col>
            <Col flex="none">
                {
                    onClick && (
                        <Button
                            type="primary"
                            className='btn-primary'
                            icon={<FaArrowRight />}
                            onClick={onClick}
                        />
                    )
                }
            </Col>
        </Row>
        <Row align={"middle"} className="card-content">
            <Col flex="auto">
                <Space size={2}>
                    <Text className="card-monto">{monto}</Text>
                    <Text className="card-suffix">{suffix}</Text>
                </Space>
            </Col>
            <Col flex="none" className="center">
                {
                    icon && (
                        <div
                            className="icon-container"
                            style={{
                                backgroundColor: color + "B2"
                            }}
                        >
                            {
                                typeof icon === 'function' ? icon() : icon
                            }
                        </div>
                    )
                }
            </Col>
        </Row>
        {
            percent !== undefined && (
                <div className={`card-percent ${aumento ? "green" : "red"}`}>
                    <Space align='center'>
                        {
                            aumento ? <FiArrowUpRight className='card-icon' color='#00CD98' /> : <FiArrowDownRight className='card-icon' color='#FF4D4F' />
                        }
                        <Text className="card-monto">{aumento ? "+" : "-"} {Math.abs(percent)}%</Text>
                    </Space>
                </div>
            )
        }
    </Card>
}



/**
 * @const CardSimpleInfo
 * @description Card para mostrar informacion simple o numerica
 * @param {*} step Object con la información del step
 * @param String color Color del step
 */
const CardSimpleInfo = ({ title = "Profit", toolTipText = 'success', info = 0 , content}) => {
	return <Card className="card-shadow card-simple">
		<Row>
			<Col span={24} className="flex-left">
				<Text className="title-simple">{title}</Text>
			</Col>
			<Col span={24} className="" >
				{ content ?? <Paragraph className="info-simple mb-0">{info}</Paragraph> }
			</Col>
		</Row>
	</Card>
}

const CardAddress = ({item, onEdit, onDelete, disabledEdit, disabledDelete}) => {

    let place = [
        `${item.direccion}`,
        `${item.codigo_postal}`
    ]

    return (
        <Card title={item.alias} className='card-direcciones' extra={<Space>
            <ButtonEdit onClick={onEdit} disabled={disabledEdit}/>
            <ButtonDelete
                disabled={disabledDelete}
                onConfirm={onDelete}
            />
        </Space>}>
            <Space direction='vertical' size={5}>
                {
                    place.map((p, i) => (<Text key={`${item._id}-place-${i}`}>{p}</Text>))
                }
                <Paragraph className='text-gray card-direcciones-descripcion' ellipsis={{
                    rows: 3
                }}>
                    {item.notas}
                </Paragraph>
            </Space>
        </Card>
    )
}

/**
 *
 * @const CardProducto
 * @param {*} {producto, onEditClick, onDeleteClick} objeto y funciones para manejar la informacion del producto
 * @description Card del producto con la informacion para cotizar en marketplace
 */
const CardProducto = ({ producto, onEditClick, onDeleteClick }) => {

    return (
        <Card
            className="card-producto"
            style={{ width: "100%" }}
        >
            <Row justify="space-between" style={{ width: "100%" }}>
                {
                    producto.imagen ? (
                        <Col xs={24} lg={4} style={{ marginRight: "1rem" }}>
                            <img
                                src={`${producto.imagen[0].thumbUrl || "ni idea"}`}
                                alt=""
                                className="card-producto-thumb"
                            />
                        </Col>
                    ) : null
                }
                <Col xs={24} lg={producto.imagen ? 19 : 24} className='card-producto-body' >
                    <Row justify="space-between">
                        <Col>
                            <Space size={16}>
                                {
                                    producto.marca ? (
                                        <Text>
                                            {producto.marca ?? "Marca N/A"}
                                        </Text>
                                    ) : null
                                }
                                {(producto.proveedor?._id) ? <Text>{producto.proveedor?.nombre}</Text> : null}
                                <Text>
                                    {producto.modelo ?? "Modelo N/A"} {producto.sku ?? "SKU N/A"}
                                </Text>
                            </Space>
                        </Col>
                        <Col>
                            {onEditClick ? <Button className="card-producto-button" onClick={onEditClick}>
                                <EditFilled style={{
                                    fontSize: "1rem"
                                }} />
                            </Button> : null}

                            {onDeleteClick ? <Button className="card-producto-button" onClick={onDeleteClick}>
                                <DeleteFilled style={{
                                    fontSize: "1rem"
                                }} />
                            </Button> : null}
                        </Col>
                    </Row>
                    <Row justify="space-between" style={{ flexWrap: "nowrap", marginTop: "0.5rem" }}>
                        <Col style={{ flexGrow: 1, paddingRight: "2rem" }}>
                            <Text style={{ fontSize: "0.75rem", maxWidth: 600, display: "block" }}>
                                {
                                    producto.descripcion ? (
                                        producto.descripcion.slice(0, 200) + " ..."
                                    ) : ("Descripción no disponible")
                                }
                            </Text>
                        </Col>
                        <Col className='card-producto-numbers'>
                            <Text style={{ fontSize: "0.75rem", display: "block" }}>
                                {
                                    producto.um ?
                                        `${producto.cantidad} ${producto.um}`
                                        : null
                                }
                            </Text>
                            <Text style={{ fontSize: "0.75rem", display: "block" }}>
                                {
                                    producto.precio_ref ?
                                        `$${producto.precio_ref.toMoney(true)}`
                                        : ""
                                }
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    )
}


/**
 *
 * @const CardProducto
 * @param {*} {producto, addToCart} objeto y funciones para manejar la informacion del producto
 * @description Card del producto con la informacion para la parte publica de los productos para que los clientes vean la informacion y lo puedan
 * agregar a su carrito de compra
 */
const CardProductoPublic = ({ producto, addToCart=()=>{} }) => {

    return <Card className="item" key={producto._id}>
        <Image
            className="image"
            width={230}
            {...(producto.imagenes?.length > 0 ? ({
                src: axios.defaults.baseURL + "/public/productos/" + producto._id + "?imagen=" + producto.imagenes[0]?.file
            }) : ({
                src: "./img/no-image.png"
            }))}
        />
        <Row style={{ marginTop: "0.6rem" }}>
            <Col xs={24}>
                <Paragraph style={{ marginBottom: "0.3em", height: "44px" }} ellipsis={{ rows: 2 }}>
                    {producto.nombre}
                </Paragraph>
            </Col>
            <Col xs={24}>
                <Paragraph style={{ marginBottom: "0.3em", height: "22px" }} ellipsis={{ rows: 1 }}>
                    {producto.modelo}
                </Paragraph>
            </Col>
            <Col xs={24}>
                <Text> <small> $ {producto.precio_venta} MXN </small> </Text>
            </Col>
            <Col xs={24} lg={24}>
                <Form
                    layout="vertical"
                    className="pd-0 mt-1"
                    onFinish={(values) => addToCart(values, producto._id)}
                    initialValues={{ cantidad: 1 }}
                >
                    <Row gutter={[16, 16]} className='width-100'>
                        <Col xs={24} >
                            <Form.Item name="cantidad" label="">
                                <InputControlProduct
                                    quantity={1}
                                    className='width-100'
                                    limit={100}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row justify="center" align="middle" >
                        <Col span={24} className="flex-column">
                            <Form.Item className="width-100">
                                <Button htmlType="submit" type="primary" className="width-100" block size='large'>
                                    Add to cart
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    </Card>

}

function InputControlProduct(props) {
    const { limit, onChange } = props
    let [value, setValue] = useState(props.value)


    const onQuantityChange = (index, quantity) => {
        const valueA = parseFloat(quantity)

        if (isNaN(valueA))
            return message.error("No es un numero valido")

        if (valueA > limit)
            return message.error("No hay existencias suficientes.")

        if (valueA <= 0)
            return message.error("Debe ser al menos uno.")

        onChange(valueA)
        setValue(valueA)
    }

    return <ProductControl
        onQuantityChange={onQuantityChange}
        index={null}
        quantity={value}
        className='width-100'
        limit={limit}
        deleteOption={false}

    />
}


export {
    CardStatistics,
    CardSimpleInfo,
    CardAddress,
    CardProducto,
    CardProductoPublic
}