import React, { useContext } from 'react'
import { Route, Routes, useNavigate } from "react-router-dom";
import { Layout } from 'antd';

import Navbar from '../Components/Customer/Navigation/Navbar'
import Sidebar from '../Components/Customer/Navigation/Sidebar'
import Dashboard from '../Components/Customer/Dashboard/Dashboard';
import axios from 'axios';

import '../Styles/Global/admin.scss'
import '../Styles/Global/global.scss'
import User from '../Hooks/Logged';
import { Carrito, SetCarrito } from '../Hooks/Carrito';

import { Search } from '../Hooks/useSearch';
import RouterCuenta from './Customer/RouterCuenta';
import RouterPedidos from './Customer/RouterPedidos';
import Cotizador from '../Components/Customer/Cotizador';
import RouterQuotations from './Customer/RouterQuotations';
import RouterTransacciones from './Customer/RouterTransacciones';

const { Content } = Layout

/**
 * 
 * @export
 * @function CustomerRoutes
 * @description Router for handling all admin routes
 */
class CustomerRoutes extends React.Component {

  static contextType = User


  constructor(props) {
    super(props)
    this.state = {
      search: null,
      showSearch: false,
      clear: true,
      carrito: [],
    }
  }

  componentDidMount() {
    this.axiosInterceptor = axios.interceptors.response.use(
      (response) => {
        //console.log('response interceptor', response)
        return response;
      },
      (error) => {
        console.log('error interceptor', error?.response?.status)
        if (error.response?.status === 401)
          this.props.navigate('/login')
        return Promise.reject(error);
      }
    );
  }
  componentWillUnmount() { axios.interceptors.request.eject(this.axiosInterceptor); }

  setSearch = search => this.setState({ search })

  setCarrito = carrito => this.setState({ carrito })

  setShowSearch = showSearch => this.setState({ showSearch })

  componentWillUnmount() {
    axios.interceptors.request.eject(this.updateToken)
  }



  render() {
    return (
      <Search.Provider value={{
        search: this.state.search,
        setSearch: this.setSearch,
        show: this.state.showSearch,
        setShow: this.setShowSearch
      }}>
        
            <Layout className='layout-main'>
              <Navbar showSearch={this.state.showSearch} onSearch={this.setSearch} />
              <Layout>
                <Sidebar />
                <Content style={{ minHeight: 'calc(100vh - 100px)' }}>
                  <Routes>
                    <Route path='/dashboard' element={<Dashboard />} />
                    <Route path='/cotizador' element={<Cotizador />} />
                    <Route path='/quotations/*' element={<RouterQuotations />} />
                    <Route path='/addresses/*' element={<RouterCuenta />} />
                    <Route path='/transactions/*' element={<RouterTransacciones />} />
                    <Route path='/pedidos/*' element={<RouterPedidos />} />
                  </Routes>
                </Content>
              </Layout>

            </Layout>


      </Search.Provider>
    )
  }


}
export default function (props) {

  const user = useContext(User)
  const navigate = useNavigate()
  return <CustomerRoutes {...props} user={user} navigate={navigate} />
}