import React, { Component } from 'react';
import {
    Row, Col, Typography,
    Layout, Space, message, List, Card, Dropdown, Tag, Menu
} from 'antd'
import { useNavigate } from "react-router-dom";
import axios, { } from 'axios';

//Componentes
import Sort from "../../Widgets/Sort"
import User from '../../../Hooks/Logged'
import useSearch from '../../../Hooks/useSearch';
import usePermissions from '../../../Hooks/usePermissions';
import { getResponseError, RenderMoney } from '../../Utils';
import FloatingButton from '../../Widgets/Floating Button/FloatingButton'
import { ButtonDelete, ButtonEdit, ButtonView } from '../../Widgets/Buttons';


import dayjs from 'dayjs';

const { Content } = Layout;
const { Text } = Typography


/**
 *
 *
 * @export
 * @class Clientes
 * @extends {Component}
 * @description Vista donde se listan todos los Clientes
 */
class VentasList extends Component {



    /**
     *Creates an instance of Clientes.
     * @param {*} props
     * @memberof Clientes
     */
    constructor(props) {
        super(props)
        //let { page, limit, filters, search, sort } = this.props.params
        this.state = {
            loading: false,

            ventas: {
                data: [],
                page: 1,
                limit: 10,
                filters: [],
                total: 0,
                sort: {},
            },
            vendedor_id: undefined,
            modalVenta: false,
            search: this.props.search,
        }
    }

    drawerFiltros = React.createRef()

    componentDidMount() {
        this.props.setShowSearch(true)

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getVentas();
        // this.getAsesores()

    }



    /**
    * @memberOf Clientes
    * @method getVentas
    * @description Obtiene ls lista de Clientes
    */
    getVentas = ({

        page = this.state.ventas.page,
        limit = this.state.ventas.limit,
        search = this.state.ventas.search,
        sort = this.state.ventas.sort,

    } = this.state.ventas) => {
        const params = { page, limit }
        if (sort && Object.values(sort).length > 0) params.sort = JSON.stringify(sort)
        if (search) params.search = search

        this.setState({ ventas: { ...this.state.ventas, page, limit, search, sort }, loading: true })
        axios.get('/ventas', {
            params
        })
            .then(({ data }) => {

                console.log("data ventas", data)
                this.setState({
                    ventas: {
                        ...this.state.ventas,
                        ...data,
                        search
                    }
                })

            })
            .catch(error => {
                //console.log(error)
                message.error('Error al obtener la Información')
            })
            .finally(() => this.setState({ loading: false, clearCheckbox: false }))
    }


    renderEstatus = (status) => {

        switch (status) {
            case 1:
                return <Tag color="#FFE608">Pendiente</Tag>
            case 2:
                return <Tag color="#5465FF">Completo</Tag>
            case 3:
                return <Tag color="#F2802E">Cancelado</Tag>
            case 4:
                return <Tag color="#00CD98">Reembolsado</Tag>
            default:
                return <Tag color="#FFE608">Pendiente</Tag>
        }
    }

    updateEstatus = (venta_id, status) => {
        axios.put('/ventas', {
            id: venta_id,
            status
        }).then(success => {
            message.success("Estatus de venta actualizada")
            this.getVentas()
        }).catch(error => {
            message.error("Error al actualizar el estatus")
        })
    }

    /**
     *
     *
     * @param {*} key
     * @memberof VentasList
    */
    setSort = (key) => {
        let value;
        switch (this.state.ventas.sort[key]) {
            case 1:
                value = -1
                break;
            case -1:
                value = undefined
                break;
            default:
                value = 1
                break;
        }
        this.getVentas({
            sort: {
                ...this.state.ventas.sort,
                [key]: value
            }
        })
    }

    render() {
        const { ventas, loading } = this.state
        return (
            <>
                
                <Content
                    className="admin-content content-bg ventas pd-1"
                    id="ventas-layout"
                >
                    <Row gutter={[24, 24]}>
                        <Col xs={24} className="">
                            <List
                                loading={this.state.loading}
                                className="component-list"
                                itemLayout="horizontal"
                                locale={{ emptyText: "Sin registros" }}
                                dataSource={ventas.data}
                                pagination={{
                                    current: ventas.page,
                                    pageSize: ventas.limit,
                                    total: ventas.total,
                                    pageSizeOptions: [10, 20, 50, 100, 500, 1000],
                                    position: 'bottom',
                                    className: "flex-left ml-1",
                                    onChange: (page, limit) => this.getVentas({ page, limit })
                                }}
                                header={
                                    <Row align="middle">

                                        <Col flex="auto">
                                            <Row className="width-100 " align="middle" gutter={[0, 12]}>
                                                <Col xs={4} className="center" >
                                                    <Sort
                                                        sort={this.state.ventas?.sort && this.state.ventas?.sort["order_id"]} onClick={() => this.setSort("order_id")} >
                                                        <Text ellipsis strong>Folio</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={3} className="center" style={{ color: "gray" }} >
                                                    <Sort
                                                        sort={this.state.ventas?.sort && this.state.ventas?.sort['createdAt']} onClick={() => this.setSort("createdAt")} >
                                                        <Text ellipsis strong>Fecha</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={4} className="center" >
                                                    <Sort
                                                        sort={this.state.ventas?.sort && this.state.ventas?.sort["cliente_id.nombre"]} onClick={() => this.setSort("cliente_id.nombre")} >
                                                        <Text ellipsis strong>Cliente</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={4} className="center" >
                                                    <Sort
                                                        sort={this.state.ventas?.sort && this.state.ventas?.sort["tracking_number"]} onClick={() => this.setSort("tracking_number")} >
                                                        <Text ellipsis strong>Numero de Rastreo</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={3} className="center" >
                                                    <Sort
                                                        sort={this.state.ventas?.sort && this.state.ventas?.sort["status"]} onClick={() => this.setSort("status")} >
                                                        <Text ellipsis strong>Estatus</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={3} className="center" >
                                                    <Sort
                                                        sort={this.state.ventas?.sort && this.state.ventas?.sort["subtotal"]} onClick={() => this.setSort("subtotal")} >
                                                        <Text ellipsis strong>Monto</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={3} className="center" >
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                }

                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <Row align="middle">
                                                <Col flex="auto">
                                                    <Row className="width-100 " align="middle" gutter={[0, 12]}>
                                                        <Col xs={4} className="center" >
                                                            <Text ellipsis >{item.folio}</Text>
                                                        </Col>
                                                        <Col xs={3} className="center" >
                                                            <Text ellipsis strong>{dayjs(item.createdAt).format('DD/MM/YYYY')}</Text>
                                                        </Col>
                                                        <Col xs={4} className="center" >
                                                            <Text ellipsis >{item?.cliente_id?.nombre} {item?.cliente_id?.apellidos}</Text>
                                                        </Col>
                                                        <Col xs={4} className="center" >
                                                            <Text ellipsis >{item.tracking_number}</Text>
                                                        </Col>
                                                        <Col xs={3} className="center" >
                                                            <Dropdown
                                                                overlay={<Menu>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 1)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`ventas-circle small circle-1`} />
                                                                            <Text>Pendiente</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 2)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`ventas-circle small circle-2`} />
                                                                            <Text>Completo</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 3)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`ventas-circle small circle-3`} />
                                                                            <Text>Cancelado</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 4)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`ventas-circle small circle-4`} />
                                                                            <Text>Reembolsado</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                </Menu>}
                                                                className="ventas-dropdown"
                                                                trigger={['click']}
                                                                disabled={!this.props.canEdit}
                                                            >
                                                                {this.renderEstatus(item.status)}
                                                            </Dropdown>
                                                        </Col>
                                                        <Col xs={3} className="center" >
                                                            <RenderMoney monto={item.total}/>
                                                        </Col>
                                                        <Col xs={3} className="center" >
                                                            <Space wrap>
                                                                <ButtonView
                                                                    onClick={()=>this.props.navigate(`/admin/ventas/${item._id}`)}
                                                                />
                                                                <ButtonEdit
                                                                    disabled={!this.props.canEdit}
                                                                    onClick={() => this.setState({ modalVenta: true, venta_id: item._id })} />
                                                                <ButtonDelete
                                                                    disabled={!this.props.canDelete}
                                                                    onConfirm={() => {
                                                                        axios.delete('/ventas', { params: { venta_id: item._id } })
                                                                            .then((response) => {
                                                                                message.success("It was deleted correctly")
                                                                                this.getVentas()
                                                                            })
                                                                            .catch((error) => {
                                                                                message.error(getResponseError(error.response, "Delete error"));
                                                                                this.getVentas()
                                                                            })
                                                                    }} />

                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                </Content>
                {/* </Spin> */}

                {this.props.canCreate && <FloatingButton title="New Client" disabled={!this.props.canCreate} onClick={() => this.setState({ modalVenta: true, venta_id: null })} />}
            </>
        )
    }
}



export default (props) => {
    const { setShow } = useSearch()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canCreate: ["ventas", "create"],
        canEdit: ["ventas", "edit"],
        canDelete: ["ventas", "delete"]
    })

    const navigate = useNavigate();


    return <VentasList {...props}
        {...permisos}
        setShowSearch={setShow}
        navigate={navigate}
    />
}