import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, Button, Typography, Switch, InputNumber, Checkbox } from 'antd';
import axios from "axios"

//componentes
import { getResponseError } from "../../Utils";
import SelectCategoria from "../../Widgets/Inputs/SelectCategoria";
import { AvatarLoader, ImagesUploader } from "../../Widgets/Uploaders";
import SelectSize from "../../Widgets/Inputs/SelectSizes";
import SelectCatalogos from "../../Widgets/Inputs/SelectCatalogos";

const { Text } = Typography
const CheckboxGroup = Checkbox.Group;


/**
 * @class ModalImagenes
 * @description Modal para el CRUD de Productos
 */
class ModalImagenes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            productos: [],
            indeterminate: false,
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
       

    }

    formRef = React.createRef();

    /**
    * @method onSearch
    * @description realiza la busqueda y obtiene los productos encontrados
    */
    onSearch = ({target}) => {
        console.log("search", target.value);
        axios.get('/productos', {
            params: {
                limit: 1000,
                page: 1,
                search: target.value,
            }
        }).then(({ data }) => {
            console.log("dassta", data);
            this.setState({ productos: data.data.map(e => ({value: e._id, label: e.nombre})) })
        })
        .catch(res => {
            console.log("res", res);
            message.error(res.response?.data?.message ?? "Error")
        })
        .finally(() => this.setState({ loading: false }))
    }

    onCheckAllChange = (e) => {
        const { productos } = this.state;
        const isChecked = e.target.checked;

        const allSelected = isChecked ? productos.map((e) => e.value) : [];

        this.formRef.current.setFieldsValue({
            productos_seleccionados: allSelected,
        });

        this.setState({
            indeterminate: !isChecked && allSelected.length > 0,
        });
    }

     onChangeCheckboxGroup = (checkedValues) => {
        console.log("checkedValues", checkedValues);
        const { productos } = this.state;

        this.setState({
            indeterminate:
                checkedValues.length > 0 && checkedValues.length < productos.length,
        });

        this.formRef.current.setFieldsValue({
            productos_seleccionados: checkedValues,
        });
    };


    /**
     * @method onFinish
     * @description Se ejecuta al hacer submit al formulario
     */
    onFinish = (values) => {
        const formData = new FormData()

        formData.appendMultiple({
            ...values,
            imagenes: undefined,
        })

        if (values.imagenes) {
            values.imagenes.forEach(img => {
                if (!img.fromDB) formData.append('imagenes', img, img.name)
            })
        }


        this.uploadImages(formData)
        
    }

    /**
    * @method uploadImages
    * @description Añade un nuevo registro de producto
    */
    uploadImages = (values) => {

        this.setState({ loading: true })
        axios.put('/productos-multiple', values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        })
            .then(response => {
                message.success('Productos actualizados')
                this.props.onClose()
            }).catch(error => {
                console.log("error", error);
                message.error(getResponseError(error.response, 'Error al actualizar el producto'))
            }).finally(() => this.setState({ loading: false }))
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-producto"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                    className="mt-3"
                    initialValues={{
                        activo: true
                    }}
                >
                    <Row justify="center" className="center">
                        <Col span={20}>
                            <Form.Item
                                label="Nombre"
                            >
                                <Input onChange={this.onSearch}/>
                            </Form.Item>

                            <Form.Item
                                name="imagenes"
                                label="Imagenes"
                            >
                                <ImagesUploader
                                    listType="picture-card"
                                    showUploadList={true}
                                    imageCrop={true}
                                    onRemove={({ name, fromDB }) => {
                                        if (fromDB) axios.put(`/productos`, {
                                            id: this.props.producto_id,
                                            delete_image: name
                                        })
                                    }}
                                />
                            </Form.Item>

                            <Form.Item>
                                <Checkbox 
                                    indeterminate={this.state.indeterminate} 
                                    onChange={this.onCheckAllChange} 
                                    checked={
                                        this.formRef.current?.getFieldValue('productos_seleccionados')?.length === this.state.productos.length &&
                                        this.state.productos.length > 0
                                    }
                                >
                                    Seleccionar Todos
                                </Checkbox>
                            </Form.Item>

                            <Form.Item 
                                name="productos_seleccionados" 
                                label={`Productos (${this.state.productos.length} encontrados)`}
                            >
                                <CheckboxGroup options={this.state.productos} />
                            </Form.Item>

                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { visible = false, onClose = () => { }, producto_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        closable={true}
        destroyOnClose={true}
        okText="Guardar"
        cancelText="Cancelar"
        zIndex={1000}
        okButtonProps={{ form: 'form-producto', key: 'submit', htmlType: 'submit' }}
        width={700}
    >
        <ModalImagenes  {...props} />
    </Modal>

}