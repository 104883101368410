import React, { useState, useEffect } from 'react';
import { Select, Tooltip, message } from 'antd'
import axios from "axios"

const { Option } = Select

/**
 * @const SelectCategoria
 * @description Select para los usuarios registrados en el sistema
 */
const SelectCategoria = (props) => {

    const {
        value,
        onChange,
        placeholder = "Seleccione una categoría",
        onSelect = () => { },
        disabled = false,
        className = "width-100",
        params = {},
        bordered,
        allowClear = true,
        onClear = () => { },
        mode = null,
        labelInValue
    } = props

    const [categorias, setCategorias] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getUsuarios
     * @description Obitiene los usaurios
     */
    const getCategorias = ({
        page,
        limit,
        search,
    } = categorias) => {

        axios.get('/categorias', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setCategorias(data)
        }).catch(error => {
            console.log("ERR, error", error)
            message.error('Error al obtener las categorías')
        })
    }

    //componentDidMount
    useEffect(() => {
        getCategorias()
    }, [])

    /*useEffect(() => {
        if (value)
            onChange(value?.value ?? value)
    }, [value])*/


    return (
        <Select
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            mode={mode}
            onSearch={(search) => getCategorias({ search })}
            onSelect={(usuario)=> {
                if(mode === null && !labelInValue){
                    onChange(usuario.value)
                    onSelect(usuario.value)
                }
                if(mode === null && labelInValue){
                    onChange(usuario)
                    onSelect(usuario)
                }
            }}
            onChange={(values)=>{
                if(mode === "multiple" && !labelInValue){
                    let arr = values.map(e => e.value)
                    onChange(arr)
                    onSelect(arr)
                }
                if(mode === "multiple" && labelInValue){
                    onChange(values)
                    onSelect(values)
                }
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
            options={categorias.data.map(e => ({ value: e._id, label: e.nombre }))}
        >
            
        </Select>
    );
}



export default SelectCategoria;